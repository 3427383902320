<template>
  <div class="vx-row">
    <div class="vx-col w-full">
      <vx-card >
        <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
          <div class="mt-6 flex items-center justify-between px-6">
            <h4>Campagne: {{ titreCampagne }}</h4>
            <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
          </div>
          <vs-divider class="mb-0"></vs-divider>
          <div class="w-full flex mt-10">
            <div class="w-1/2">
              <div class="analyze-preview-container" style="margin-left: 200px;">
                <div class="phone-container sms ios wl ">
                  <div class="phone-content">
                    <p class="sender fs-exclude">{{from}}</p>
                    <p class="msg-details">Message texte<br>ven. 4 févr. {{(new Date().getFullYear())}} {{new Date().getHours()}}:{{new Date().getMinutes()}}</p>
                    <div class="msg-container fs-exclude">
                      <span dir="auto" class="msg-content">
                        {{text}}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="w-1/2">
              <vx-card :noShadow="true" :cardBorder="true" title="Résumé">
                  <div class="vx-row">

                    <div class="vx-col md:w-1/2 mt-4">
                      <span class="font-bold">
                        Destinataires
                      </span> <br>
                      <span class="font-bold">
                        {{destinations.length}}
                      </span>
                    </div>
                    <div class="vx-col md:w-1/2 mt-4">
                      <span class="font-bold">
                        Messages
                      </span> <br>
                      <span class="font-bold">
                        {{Math.ceil((text.length/160))}}
                      </span>
                    </div>
                    <div class="vx-col md:w-1/2 mt-4">
                      <span class="font-bold">
                        Message le plus court:
                      </span>
                    </div>
                    <div class="vx-col md:w-1/2 mt-4">
                      <span class="font-medium">
                        {{messageCourt}} caractères
                      </span>
                    </div>
                    <div class="vx-col md:w-1/2 mt-4">
                      <span class="font-bold">
                        Message le plus long:
                      </span>
                    </div>
                    <div class="vx-col md:w-1/2 mt-4">
                      <span class="font-medium" v-if="messageLong">
                        {{messageLong.length > 0 ? Math.max(...messageLong) : ''}} caractères
                      </span>
                    </div>
                    <vs-divider></vs-divider>
                    <div class="vx-col w-full mt-4">
                      <h5>Options avancées</h5>
                    </div>
                    <div class="vx-col md:w-1/2 mt-4">
                      <span class="font-bold">
                        Période de validité:
                      </span>
                    </div>
                    <div class="vx-col md:w-1/2 mt-4">
                      <span class="font-medium">
                        48 heures
                      </span>
                    </div>
                    <div class="vx-col md:w-1/2 mt-4">
                      <span class="font-bold">
                        Vitesse de livraison:
                      </span>
                    </div>
                    <div class="vx-col md:w-1/2 mt-4">
                      <span class="font-medium">
                        5 sms/s
                      </span>
                    </div>
                    <vs-divider></vs-divider>
                    <div class="vx-col w-full mt-4">
                      <h5>Options de planification</h5>
                    </div>
                    <div class="vx-col md:w-1/2 mt-4">
                      <span class="font-bold">
                        Fuseau horaire:
                      </span>
                    </div>
                    <div class="vx-col md:w-1/2 mt-4">
                      <span class="font-medium">
                        {{fuseauHoraire}}
                      </span>
                    </div>
                    <div class="vx-col md:w-1/2 mt-4">
                      <span class="font-bold">
                        Créneau horaire de l'envoi:
                      </span>
                    </div>
                    <div class="vx-col md:w-1/2 mt-4">
                      <span class="font-medium">
                        <template v-if="days.length === 7">
                          Quotidien {{hour}}H{{minute}}mn - {{toHour}}H{{toMinute}}mn
                        </template>
                        <template v-else>
                          <span v-for="(jour, index) in days" :key="index">
                            <template v-if="jour === 1">
                              Lundi,
                            </template>
                            <template v-if="jour === 2">
                              Mardi,
                            </template>
                            <template v-if="jour === 3">
                              Mercredi,
                            </template>
                            <template v-if="jour === 4">
                              Jeudi,
                            </template>
                            <template v-if="jour === 5">
                              Vendredi,
                            </template>
                            <template v-if="jour === 6">
                              Samedi
                            </template>
                            <template v-if="jour === 0">
                              Dimanche
                            </template>
                          </span> <br> {{hour}}H{{minute}}mn - {{toHour}}H{{toMinute}}mn
                        </template>
                      </span>
                    </div>
                    <div class="vx-col w-full mt-4">
                      <vs-button @click="startCampagneSmsMessage">LANCER LA DIFFUSION</vs-button>
                    </div>
                  </div>
                </vx-card>
            </div>
          </div>
        </vs-sidebar>
        <div class="vx-row mb-6">
          <div class="vx-col w-full">
            <vs-table stripe noDataText="Aucune diffusion" pagination max-items="20" search :data="diffusions">
              <template slot="header">
                <vs-button class="mb-4 mr-5 md:mb-0" @click="$router.push('/apps/sms-marketing/create-diffusion')">CRÉER UNE DIFFUSION</vs-button>
                <vs-select placeholder="Officine" autocomplete class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="idOfficine" @change="CurrentOfficineSelectDiffusion()">
                  <vs-select-item  class="w-full" :key="index" :value="item._id" :text="item.NomOffic" v-for="(item,index) in officines" />
                </vs-select>
                <span class="ml-5 text-xl font-semibold" style="">
                  SMS :
                </span>
                <span class="ml-2 text-xl font-semibold" style="color: red">
                  {{ balance ? balance.availableUnits : '' }}
                </span>

              </template>
              <template slot="thead">
                <vs-th sort-key="_id">
                  Titre Diffusion
                </vs-th>
                <vs-th sort-key="Idprescripteur">Destinations</vs-th>
                <vs-th sort-key="Idprescripteur">Taux de conversion</vs-th>
                <vs-th>Actions</vs-th>
              </template>

              <template slot-scope="{data}">
                <tbody>
                  <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>
                      {{ tr.titreCampagne }}
                    </vs-td>

                    <vs-td>
                      {{ tr.destinations ? tr.destinations.length : ''}}
                    </vs-td>

                    <vs-td>
                      {{ tr.destinations ? (tr.destinations.filter((d) => d.statusMessage === 'sended') ? tr.destinations.filter((d) => d.statusMessage === 'sended').length : 0) : 0}} /{{ tr.destinations ? tr.destinations.length : ''}}
                    </vs-td>

                    <vs-td class="whitespace-no-wrap">
                      <feather-icon title="Details" icon="EyeIcon" svgClasses="w-5 h-5 hover:text-grey stroke-current ml-4" @click.stop="showDiffusion(tr)" />
                      <feather-icon title="Editer" icon="Edit3Icon" svgClasses="w-5 h-5 hover:text-primary stroke-current ml-2" @click.stop="editDiffusion(tr._id)" />
                      <feather-icon title="Supprimer" icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click.stop="confirmDeleteRecord(tr._id)" />
                    </vs-td>

                  </vs-tr>
                </tbody>
              </template>
            </vs-table>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      selected:[],
      ordonnances:[],
      ordonnanceId: '',
      idOfficine: null,
      activeUserInfos: null,
      idDiffusion: null,
      balance: null,
      isSidebarActiveLocal: false,

      id: null,
      titreCampagne: null,
      from: null,
      fuseauHoraire: null,
      days: [],
      destinations: [],
      text: '',
      hour: null,
      minute: null,
      toHour: null,
      toMinute: null,
      courtMessage: '',
      longMessage: '',
      restMessage: '',
      messageLengthArray: []
    }
  },
  computed: {
    diffusions () {
      return this.$store.state.sms_marketing.diffusions
    },
    clients () {
      return this.$store.state.client.clients
    },
    officines () {
      return this.$store.state.officine.officines
    },
    messageCourt () {
      const quotient = Math.ceil((this.text.length / 160))
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.courtMessage = this.text
      for (let index = 1; index <= quotient; index++) {
        if (this.courtMessage.length > 160) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.courtMessage = this.courtMessage.substring(160, this.courtMessage.length)

        } else {
          return this.courtMessage.length
        }

      }

    },
    messageLong () {
      const quotient = Math.ceil((this.text.length / 160))
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.longMessage = this.text
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.restMessage = this.text
      for (let index = 0; index < quotient; index++) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        if (index === 0) this.messageLengthArray = []
        console.log('index', index)
        if (this.restMessage.length > 160) {
          console.log(this.restMessage.length)
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.longMessage = this.restMessage.substring(0, 160)
          console.log('new', this.longMessage.length)
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.messageLengthArray.push(this.longMessage.length)
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.restMessage = this.restMessage.substring(160, this.restMessage.length)
          console.log(this.restMessage.length)
        } else {
          return this.messageLengthArray
        }

      }
    }
  },

  methods: {
    editDiffusion (id) {
      this.$router.push(`/apps/sms-marketing/edit-diffusion/${id}`).catch((err) => { console.log(err) })
    },
    showDiffusion (campagne) {
      this.id = campagne._id
      this.from = campagne.from
      this.text = campagne.text
      this.titreCampagne = campagne.titreCampagne
      this.destinations = campagne.destinations
      this.hour = campagne.deliveryTimeWindow.from.hour
      this.minute = campagne.deliveryTimeWindow.from.minute
      this.toHour = campagne.deliveryTimeWindow.from.to.hour
      this.toMinute = campagne.deliveryTimeWindow.from.to.minute
      this.days = campagne.deliveryTimeWindow.from.to.days
      this.fuseauHoraire = campagne.fuseauHoraire
      
      this.isSidebarActiveLocal = true
    },
    confirmDeleteRecord (id) {
      this.idDiffusion = id
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Supprimer',
        text: 'Supprimer cette diffusion ?',
        acceptText: 'Supprimer',
        accept: this.deleteRecord
      })
    },
    deleteRecord () {
      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('sms_marketing/deleteDiffusion', this.idDiffusion)
        .then(() => {
          this.$vs.notify({
            title: 'Supprimer',
            text: 'supprimer!',
            iconPack: 'feather',
            icon: 'icon-trash',
            color: 'danger'
          })
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.notify({
            title: 'error',
            text: `${err.message}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
          this.$vs.loading.close()
        })
    },
    getOfficines () {
      this.$store.dispatch('officine/getOfficines')
        .then(() => {

        })
        .catch((err) => {
          console.log(err)
          this.$vs.notify({
            title: 'error',
            text: `${err.message}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    activeUserInfo () {
      this.activeUserInfos = JSON.parse(localStorage.getItem('userInfo'))
      this.idOfficine = this.activeUserInfos.IdOfficine

      this.getDiffusionByOfficine(JSON.parse(localStorage.getItem('userInfo')).IdOfficine)
    },
    CurrentOfficineSelectDiffusion () {
      this.getDiffusionByOfficine(this.idOfficine)
    },
    startCampagneSmsMessage () {
      this.$store.dispatch('sms_marketing/startCampagneSmsMessage', this.id)
        .then(() => {
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'error',
            text: `${err.message}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    getDiffusionByOfficine (id) {
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('sms_marketing/getDiffusionByOfficine', id)
        .then(() => {
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'error',
            text: `${err.message}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    },
    getBalanceSMS () {
      this.$store.dispatch('sms_marketing/getBalanceSMS')
        .then((response) => {
          this.balance = response.data.balance[0]
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'error',
            text: `${err.message}`,
            iconPack: 'feather',
            icon: 'icon-error',
            color: 'danger'
          })
        })
    }
  },
  created () {
    this.activeUserInfo()
    this.getOfficines()
    this.getBalanceSMS()
  }
}
</script>
<style lang="scss" scoped>
.analyze-preview-container {
    width: 100%;
    text-align: center;
  }
  .analyze-preview-container .phone-container.sms.wl {
    background-image: url(https://d2ygi9hnn4h2ig.cloudfront.net/broadcast-sms/1.7.41/images/phone-sms-no-logo.png);
  }
  .analyze-preview-container .phone-container.sms {

    background-size: 100%;
    background-position: 50%;
    width: 322px;
    height: 634px;
    top: 120px;
   /*  left: calc(50% - 211px); */
    transform: translateX(-50%);
    margin-bottom: 120px;
    background-image: url(https://d2ygi9hnn4h2ig.cloudfront.net/broadcast-sms/1.7.41/images/phone-sms.png);
}

.analyze-preview-container .phone-content {
    position: absolute;
    top: 81px;
    left: 24px;
    width: 273px;
    height: 476px;
    overflow: hidden;
}

.analyze-preview-container .phone-container.sms .sender {
    margin-top: 50px;
}

.analyze-preview-container .phone-container.sms .msg-details {
    font-size: smaller;
}

.analyze-preview-container .phone-container.sms .msg-container {
    height: 318px;
    overflow: hidden;
    overflow-y: auto;
    overflow-wrap: break-word;
    word-break: break-word;
}

.analyze-preview-container .phone-container.sms .msg-content {
    background-color: #e6e6eb;
    text-align: left;
    padding: 5px 10px;
    margin: 5px 10px;
    border-radius: 20px;
    float: left;
    white-space: pre-wrap;
}
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 80vw;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}
</style>

